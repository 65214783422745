import './App.css'
import { useState } from 'react'

function App () {
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({
    email: '',
    password: '',
    userData: false
  })
  const handleChange = e => {
    e.preventDefault()
    setData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    console.log(data)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    if (data.email.length < 8 || data.password.length !== 4) {
      setSuccess('')
      setIsLoading(false)
      setError('Invalid credential')

      return null
    }
    try {
      await fetch(
        `https://core.luca.africa/user/delete/user/account?email=${data.email}&pin=${data.password}`,
        {
          method: 'GET',
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )

      setError('')
      setIsLoading(false)
      setSuccess('Account deleted successfully')
    } catch (error) {
      console.error('Error making POST request', error)
      setSuccess('')
      setIsLoading(false)
      setError(error?.response?.message || 'server error, try again')
      // Handle the error
    }
  }

  return (
    <div className='main'>
      <div className='form'>
        {error && <h4 className='error'>{error}</h4>}
        {success && <h4 className='success'>{success}</h4>}
        <div className='input-container'>
          <label>Phone/Email</label>
          <input
            type='text'
            name='email'
            value={data.email}
            onChange={handleChange}
            placeholder='enter your phone number or email'
          />
        </div>
        <div className='input-container'>
          <label>Password</label>
          <input
            type='password'
            name='password'
            value={data.password}
            onChange={handleChange}
            placeholder='enter your password'
          />
        </div>
        <div className='checkbox-container'>
          <input type='checkbox' name='userData' />
          <label>delete all my data</label>
        </div>
        <button disabled={isLoading} onClick={handleSubmit} className='submit'>
          {isLoading ? 'Loading...' : 'Delete my account'}
        </button>
      </div>
    </div>
  )
}

export default App
